<template>
  <div>
    <ManageBanners_5 />
  </div>
</template>
<script>
import ManageBanners_5 from "@/components/Banners_5/ManageBanners_5";
export default {
  components: {
    ManageBanners_5,
  },
  created() {
  },
};
</script>
